import React, { Component } from 'react';
import ImageBlock from '../components/imageBlock';
import styles from '../styles/scss/pages/about.module.scss';
import NewLayout from '../layout/newLayout'
import SEO from "../components/seo"
import PageContents from '../components/pageContents'
class About extends Component {
    render() {

        var pg = this.props.pageContext.page

        return (
            <NewLayout>
                {pg.seo.title && <h1 style={{ display: 'none' }}>{pg.seo.title}</h1>}
                <SEO page={pg} />
                <div className='d-flex flex-column flex-fill'>
                    <div className={styles.container}>
                        <PageContents content={pg.content} />
                        <div className={styles.images}>
                            <div className={styles.imageItems}>
                                <ImageBlock
                                    hoverEnabled={true}
                                    title='History'
                                    image='/img/history.png'
                                    url='/history'
                                    containerClass={styles.containerBig}
                                    imageClass={styles.imageSize}
                                    titleClass={styles.titleBig}
                                />
                                <ImageBlock
                                    hoverEnabled={true}
                                    title='Careers'
                                    image='/img/careers.png'
                                    url='https://www.wd40company.com/careers/'
                                    containerClass={styles.containerBig}
                                    imageClass={styles.imageSize}
                                    titleClass={styles.titleBig}
                                />
                            </div>
                            <div className={styles.imageItems}>
                                <ImageBlock
                                    hoverEnabled={true}
                                    title='The Myths, Legends & Fun Facts'
                                    image='/img/myths.png'
                                    url='/myths-legends-fun-facts'
                                    containerClass={styles.containerSmall}
                                    imageClass={styles.imageSize}
                                    titleClass={styles.titleSmall}
                                />
                                <ImageBlock
                                    hoverEnabled={true}
                                    title='WD-40 Athletes'
                                    image='/img/athletes.png'
                                    url='/athletes'
                                    containerClass={styles.containerSmall}
                                    imageClass={styles.imageSize}
                                    titleClass={styles.titleSmall}
                                />
                                <ImageBlock
                                    hoverEnabled={true}
                                    title='Brands'
                                    image='/img/brands.jpg'
                                    url='https://www.wd40company.com/what-we-do/our-brands/'
                                    containerClass={styles.containerSmall}
                                    imageClass={styles.imageSize}
                                    titleClass={styles.titleSmall}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewLayout>
        );
    }
}

export default About